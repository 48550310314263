import {
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CreateLeadDto } from 'models/lead.model';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store/store';
import { MdPerson, MdPersonAdd } from 'react-icons/md';
import { isCPFValid, isPhoneValid } from '../../../helpers/lead.helpers';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from '../../../components/icons/IconBox';
import UploadFile from './UploadFile';

export interface IModalCreateBatch {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUpload: (leads: Array<CreateLeadDto>) => void;
}

export default function ModalTypingCreateBatch({ isOpen, onClose, onUpload }: IModalCreateBatch) {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const {
   loadingUpdate
  } = useAppSelector((state: RootState) => state.typingRobot);
  const [file, setFile] = useState<string>(null);
  const [data, setData] = useState<Array<any>>([]);
  const [fileLeads, setFileLeads] = useState<Array<CreateLeadDto>>([]);

  useEffect(() => {
    if (data && data.length) {
      const newLeads: Array<CreateLeadDto> = [];
      data.forEach(l => {
        const lead = new CreateLeadDto();
        const cpf = isCPFValid(l[0] || '');
        const phoneNumber = isPhoneValid(l[2] || '');
        const name = String(l[1] || '').trim();
        if (cpf && phoneNumber) {
          lead.name = name;
          lead.phone = phoneNumber || '';
          lead.cpf = cpf;
          lead.sexo = l[3];
          lead.estadoCivil = Number(l[4]) || 6;
          lead.dataNascimento = l[5] ? l[5].padStart(10, '0') : '';
          lead.rg = l[6] || '0000';
          lead.estadoRg = l[7];
          lead.orgaoEmissor = l[8] || 'SSP';
          lead.dataExpedicao = l[9] ? l[9].padStart(10, '0') : '01/01/2000';
          lead.estadoNatural = l[10];
          lead.cidadeNatural = l[11];
          lead.nacionalidade = Number(l[12]) || 1;
          lead.paisOrigem = l[13] || 'BR';
          lead.renda = l[14] || '2000';
          lead.cep = l[15] ? l[15].replace(/\D/g, '').padStart(8, '0') : '';
          lead.endereco = l[16];
          lead.numero = Number(l[17]);
          lead.complemento = l[18];
          lead.bairro = l[19];
          lead.cidade = l[20];
          lead.estado = l[21];
          lead.nomeMae = l[22];
          lead.nomePai = l[23] || 'Não informado';
          lead.valorPatrimonio = Number(l[24]) || 1;
          lead.clienteIletradoImpossibilitado = l[25] || 'N';
          lead.banco = Number(l[26]);
          lead.agencia = Number(l[27]);
          lead.conta = l[28] ? l[28].replace(/\D/g, '') : '';
          lead.tipoConta = l[29] || 'C';
          newLeads.push(lead);
        }
      });
      setFileLeads(newLeads);
    }
  }, [data]);

  function closeModal() {
    setData([]);
    setFileLeads([]);
    onUpload(fileLeads);
    onClose();
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar base para digitação</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <UploadFile
              onFiles={(f) => setFile(f)}
              onData={(data) => setData(data)}
            />
            {data.length ? <HStack mb='20px'>
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'secondaryGray.300'}
                    icon={<Icon w='32px' h='32px' as={MdPerson} color={'brand.500'} />}
                  />
                }
                name='Linhas do arquivo'
                value={data.length}
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={'brand.500'}
                    icon={<Icon w='32px' h='32px' as={MdPersonAdd} color={'secondaryGray.300'} />}
                  />
                }
                name='Leads válidos'
                value={fileLeads.length}
              />
            </HStack> : null}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} colorScheme='blue' size={'md'} isLoading={loadingUpdate}
                  isDisabled={fileLeads.length === 0}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
